var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"filter-container"},[_c('el-form',{attrs:{"inline":""}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入标题","clearable":""},model:{value:(_vm.query.title),callback:function ($$v) {_vm.$set(_vm.query, "title", $$v)},expression:"query.title"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"placeholder":"请选择类型","clearable":""},model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},_vm._l((_vm.types),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"danger","icon":"el-icon-refresh"},on:{"click":_vm.refresh}},[_vm._v("重置")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("新增")])],1)],1)],1),_c('el-table',{attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"title","label":"标题","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"id","label":"图片","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"200px","height":"120px"},attrs:{"src":row.coverUrl,"preview-src-list":[row.coverUrl]}})]}}])}),_c('el-table-column',{attrs:{"prop":"posterType","label":"类型","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.types[row.type])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sort","label":"排序","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","align":"center","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.status[row.status])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.edit(row.id)}}},[_vm._v("编辑")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")])],1)],1),_c('el-row',{staticStyle:{"margin-top":"10px"}},[_c('el-col',{attrs:{"span":12}},[(row.status)?_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.disable(row.id)}}},[_vm._v("禁用")]):_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.enable(row.id)}}},[_vm._v("启用")])],1)],1)]}}])})],1),_c('div',{staticClass:"page-container"},[_c('el-pagination',{attrs:{"background":"","total":_vm.count,"page-sizes":_vm.pageSizes,"page-size":_vm.pageSize,"current-page":_vm.page,"layout":"total, sizes, prev, pager, next, jumper"},on:{"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"size-change":_vm.refresh,"current-change":function (val) {
        _vm.page = val
        _vm.getList()
      }}})],1),_c('Form',{ref:"form"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }